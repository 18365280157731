import React from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/WeeksSmartInstallments"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { BottomCta, RefinRedesign } from "../components/BottomCta"
import { FormPKW } from "../components/FormPKW"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { Partners } from "../components/PartnersNew"
import { Reviews } from "../components/Reviews"
import { Questions } from "../components/Questions"
import { NewFooter } from "../components/NewFooter"

import { getPageData } from "../helpers/getPageData"

import { PageData } from "../interfaces/pageProps"
import { FourAdvantagesPoints } from "../components/FourAdvantagesPoints"
import { MoreBenefitsRedisign } from "../components/MorBenefitsRedisgn"
import { SmartWeeks } from "../components/SmartWeeks"

const title = "Недели умных рассрочек по карте «Халва» — оформите карту онлайн и активируйте акцию"

const description =
  "Покупайте одежду, обувь, ювелирные изделия и путешествия в рассрочку на 36 месяцев по карте «Халва»! Узнайте сроки и подробные условия участия в акции, оформите карту Халва и активируйте «Недели умных рассрочек»"

const formTitle = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const parntersSubTitle = (
  <>
    С 30 сентября по 27 октября покупайте в рассрочку на 12 месяцев. Увеличенная рассрочка доступна
    на&nbsp;все покупки у&nbsp;партнёров акции. Наслаждайтесь покупками без&nbsp;лишних забот!
  </>
)

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)

  return (
    <Layout title={title} description={description} noHeader noFooter noIndex={noIndex}>
      <NewHeader />
      <Banner orderNum="1" />
      <SmartWeeks orderNum="2" />
      <HowInstallmentWorks
        headTitle="Как принять участие в Акции"
        variant="nedeliUmnuxRassrochek"
        orderNum="3"
      />
      <FormPKW
        formBtnText="Оформить карту"
        title={formTitle}
        withTimer
        dataLayerName="shortPersonalForm"
        orderNum="4"
      />
      <FourAdvantagesPoints variant="nedeliUmnyhRassrochek" indent={false} withTitle orderNum="5" />
      <NewCalculator orderNum="6" />
      <Partners
        title="Более 260 000 магазинов-партнеров"
        subTitle={parntersSubTitle}
        orderNum="7"
      />
      <MoreBenefitsRedisign additionalEventInDataLayer={false} bottomBlock={false} orderNum="8" />
      <BottomCta variant="pkwRedesign" BgImg={RefinRedesign} orderNum="9" />
      <Questions orderNum="10" />
      <Reviews orderNum="11" />
      <NewFooter ligal={ligal} orderNum="12" />
    </Layout>
  )
}

export const query = graphql`
  query {
    admin {
      page(url: "https://halvacard.ru/order/nedeli-umnyh-rassrochek/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
